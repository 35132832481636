import React, { useContext } from 'react'
import loadable from '@loadable/component'
import { OptionsContext } from '/src/contexts/OptionsContext'
import { StaticImage } from 'gatsby-plugin-image'
import parse from 'html-react-parser'
import './styles.scss'

const BackToTop = loadable(() => import('/src/components/Structure/BackToTop'))

const FooterReduced = () => {
    const [options] = useContext(OptionsContext)
    const footerOptions = options.footer

    return (
        <footer className="c-section c-footer-reduced">
            <div className="container-lg">
                <div className="c-footer-reduced__logo-container">
                    <StaticImage
                        src={'../../../images/pl-vertical-white.png'}
                        width={125}
                        alt={`${options.siteData.title} logo`}
                        className={'c-footer-reduced__logo'}
                    />

                    <StaticImage
                        src={'../../../images/fscs-logo-white.png'}
                        width={125}
                        alt={'FSCS logo'}
                        className={'c-footer-reduced__logo'}
                    />
                </div>

                <div className="c-footer-reduced__copyright">{parse(footerOptions.copyrightReduced)}</div>
            </div>

            <BackToTop showBelow={300} />
        </footer>
    )
}

export default FooterReduced
